<template>
</template>

<script>
export default {
    created(){
      this.$router.push('/prazos')
    }
}
</script>
