import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";

require('./assets/css/bootstrap-datepicker.standalone.min.css');
require('./assets/css/bootstrap.min.css');
require('./assets/css/fontawesome-all.min.css');

const app = createApp(App)
    .use(router)
    .use(VueGtag, 
        {
          config: { id: "UA-117676080-1" }
        }, router)

app.config.globalProperties.axios=axios
app.mount('#app')

